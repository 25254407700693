import React from "react"

import "./CustomCarouselArrow.scss"

function CustomCarouselArrow({ position = "next", onClick }) {
  return (
    <button className={`CustomCarouselArrow ${position}`} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.23381 7.83996C-0.0580881 7.61134 -0.0795999 7.22214 0.185762 6.97066L3.32039 4L0.185762 1.02934C-0.0796002 0.77786 -0.0580884 0.388661 0.23381 0.160042C0.525708 -0.0685778 0.977456 -0.0500441 1.24282 0.201436L4.81424 3.58605C5.06192 3.82077 5.06192 4.17923 4.81424 4.41395L1.24282 7.79856C0.977456 8.05005 0.525708 8.06858 0.23381 7.83996Z"
              fill="#999999"/>
      </svg>
    </button>
  )
}

export default CustomCarouselArrow
