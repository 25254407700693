const isNull = value => value === null;

const formIsValid = (form, required = []) => Object.keys(form).every((field) => {
  if (required.includes(field)) {
    return (
      form[field] === false
      || (Array.isArray(form[field])
        && form[field].every(value => value === false))
    )
  }
  if (
    isNull(form[field])
    || (Array.isArray(form[field]) && form[field].every(value => isNull(value)))
  ) {
    return true
  }
  return (
    form[field] === false
    || (Array.isArray(form[field])
      && form[field].every(value => value === false))
  )
})

export default formIsValid
