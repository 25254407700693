import React, { useEffect } from "react"
import PropTypes from "prop-types"

import "./Layout.scss"
import { Header } from "../Header"
import { Footer } from "../Footer"

const Layout = ({ children }) => (
  <>
    <Header/>
    <main>{children}</main>
    <Footer/>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
