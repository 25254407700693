import React, { useState, useEffect } from "react"
import logo from "../../../images/BDI-logo.svg"
import logoBlack from "../../../images/logo-black.svg"
import menu from "../../../images/menu.svg"
import close from "../../../images/close.svg"
import { Link } from "gatsby"
import SocialList from "../../common/SocialList/SocialList"
import cn from "classnames"
import "./Header.scss"

const navs = [
  {
    id: 1,
    title: "Главная",
    link: "/",
  },
  {
    id: 2,
    title: "О нас",
    link: "/#about",
  },
  {
    id: 3,
    title: "Этапы создания дизайн-проекта",
    link: "/#steps",
  },
  {
    id: 4,
    title: "Наши работы",
    link: "/#works",
  },
  {
    id: 5,
    title: "Наши услуги",
    link: "/#services",
  },
  {
    id: 6,
    title: "Стоимость",
    link: "/#price",
  },
  {
    id: 7,
    title: "Контакты",
    link: "/#contacts",
  },
]

function Header() {
  const [isSticky, setSticky] = useState(false)
  const [isMobileMenu, setMobileMenu] = useState(false)

  const handleScroll = () => setSticky(window.scrollY > 20)
  const toggleMobileMenu = () => setMobileMenu(prev => !prev)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <header className={cn("Header", {
      sticky: isSticky,
    })}>
      <div className="Header-content">
        <Link
          to="/"
          className="Header-logo"
        >
          <img src={logo} alt=""/>
        </Link>
        <SocialList/>
        <img onClick={toggleMobileMenu} className="Header-menu" src={menu} alt="menu"/>
      </div>

      <div className={cn("Header-mobile-menu", {
        active: isMobileMenu,
      })}>
        <div className="Header-mobile-menu-top">
          <img className="Header-mobile-menu-logo" src={logoBlack} alt="logoBlack"/>
          <img onClick={toggleMobileMenu} className="Header-close" src={close} alt="close"/>
        </div>
        <ul className="Header-list">
          {
            navs.map(nav => (
              <li key={nav.id} className="Header-item">
                <Link
                  to={nav.link}
                  className="Header-link"
                  onClick={toggleMobileMenu}
                >
                  {nav.title}
                </Link>
              </li>
            ))
          }
        </ul>
        <SocialList/>
      </div>
    </header>
  )
}

export default Header
