import React from "react"

function Instagram() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15 15" width="15" height="15">
      <path className="cls-1"
            d="M15,5.87a14.1,14.1,0,0,0-.14-2.43A4,4,0,0,0,13.27.86a4.48,4.48,0,0,0-2.42-.8L10,0H5L4.88,0c-.48.05-1,.05-1.43.13a3.85,3.85,0,0,0-3.24,3A9.47,9.47,0,0,0,0,5.45C0,7,0,8.56,0,10.11a6.88,6.88,0,0,0,.19,1.74,3.82,3.82,0,0,0,2.26,2.71A5.92,5.92,0,0,0,4.55,15c.17,0,.34,0,.5,0H9.94l.15,0c.49,0,1-.05,1.45-.13a3.87,3.87,0,0,0,3.28-3.17A10.57,10.57,0,0,0,15,9.57C15,8.34,15,7.1,15,5.87ZM13.64,8.18c0,.9,0,1.81-.08,2.71a2.75,2.75,0,0,1-.9,2,2.62,2.62,0,0,1-1.54.64c-.59.06-1.18.09-1.76.1s-1.24,0-1.86,0v0l-3-.06A4,4,0,0,1,3,13.32a2.44,2.44,0,0,1-1.55-2,19,19,0,0,1-.13-2c0-.86,0-1.73,0-2.6s0-1.74.08-2.61a2.76,2.76,0,0,1,.9-2,2.72,2.72,0,0,1,1.54-.65c.58-.05,1.17-.08,1.75-.09.86,0,1.73,0,2.59,0s1.79,0,2.69.08a2.7,2.7,0,0,1,2,.92,2.66,2.66,0,0,1,.62,1.51c.06.59.09,1.18.1,1.77C13.65,6.49,13.65,7.33,13.64,8.18Z"/>
      <path className="cls-1"
            d="M7.5,3.64a3.86,3.86,0,1,0,3.85,3.88A3.86,3.86,0,0,0,7.5,3.64Zm0,6.35A2.49,2.49,0,1,1,10,7.54,2.49,2.49,0,0,1,7.48,10Z"/>
      <path className="cls-1" d="M11.5,2.59a.91.91,0,1,0,.9.91A.91.91,0,0,0,11.5,2.59Z"/>
    </svg>
  )
}

export default Instagram
