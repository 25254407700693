import React, { useState, useEffect } from "react"
import axios from "axios"
import { Input } from "../../common/Input"
import cn from "classnames"
import "./ContactUs.scss"
import { TextArea } from "../../common/TextArea"
import { formValid, validText } from "../../validation"
import Timer from "react-compound-timer"
import { isNumber } from "lodash"
import Button from "../../common/Button/Button"


const INITIAL_DATA = {
  name: null,
  email: null,
  phone: null,
  message: null,
}

const DEFAULT_ERRORS = {
  name: null,
  email: null,
  phone: null,
  message: null,
}


function ContactUs() {
  const [formData, setFormData] = useState({
    data: INITIAL_DATA,
    errors: DEFAULT_ERRORS,
    canSubmit: false,
  })
  const [timer, setTimer] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isAlert, setAlert] = useState(false)

  useEffect(() => {
    const timeFromStorage = window.localStorage.getItem("timer")
    setTimer(timeFromStorage ? +timeFromStorage : 1800000)

  }, [])

  useEffect(() => {
    const beforeUnloadHandler = () => {
      localStorage.setItem("timer", timer.toString())
    }
    window.addEventListener("beforeunload", beforeUnloadHandler)
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler)
    }
  })

  useEffect(() => {

    if (!isAlert) return
    setInterval(() => setAlert(false), 10000)

  }, [isAlert])

  const handleChange = ({ target: { name, value } }) => {
    const { errors, data } = formData
    const newErrors = { ...errors }

    switch (name) {
      case "name":
      case "message":
        newErrors[name] = validText({
          value,
        })
        break
      case "email":
        newErrors[name] = validText({
          value,
          type: "email",
        })
        break
      case "phone":
        newErrors[name] = validText({
          value,
          type: "phone",
        })
        break
      default:
        break
    }

    setFormData({
      data: {
        ...data,
        [name]: value,
      },
      errors: newErrors,
      canSubmit: formValid({ ...newErrors }, ["name", "phone", "message", "email"]),
    })
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      const requestData = {
        name: formData.data.name,
        email: formData.data.email,
        phone: formData.data.phone,
        message: formData.data.message,
        is_discount: isNumber(timer) && +timer > 0,
      }
      const url = process.env.REACT_URL

      await axios({
        method: "POST",
        url: `${url}/orders/`,
        data: requestData,
      })
      setFormData({
        data: {
          name: "",
          email: "",
          phone: "",
          message: "",
        },
        errors: DEFAULT_ERRORS,
        canSubmit: false,
      })
      setLoading(false)
      setAlert(true)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }

  }


  const {
    data: {
      name,
      email,
      phone,
      message,
    },
    errors,
    canSubmit,
  } = formData

  return (
    <section id="contact-us" className="ContactUs">
      {isNumber(timer) && (
        <div className="ContactUs-banner">
          <div className="container">
            <Timer
              initialTime={timer}
              direction="backward"
            >
              {({ getTime }) => {

                if (timer >= 0) setTimer(getTime)

                return getTime() > 0 && (
                  <div className="ContactUs-banner-box">
                    Отправь заявку в течение {" "}
                    <span className="ContactUs-banner-wrapper">
                            <Timer.Minutes/>:
                            <Timer.Seconds/>
                          </span>
                    минут и получи скидку <b>-10%</b>
                  </div>
                )

              }}
            </Timer>
          </div>
        </div>
      )}
      <div className="ContactUs-form-w">
        <div className="container">
          <div className="section-title">
            Связаться с нами
          </div>
          <div className={cn("ContactUs-alert", {
            active: isAlert,
          })}>
            Спасибо за заявку, мы свяжемся с Вами в течение 30 минут!
          </div>
          <div className="ContactUs-form">
            <Input
              label="Name"
              name="name"
              placeholder="Введите имя"
              value={name}
              error={errors.name}
              onChange={handleChange}
            />
            <Input
              label="Email"
              name="email"
              type="email"
              value={email}
              error={errors.email}
              placeholder="Введите почту"
              onChange={handleChange}
            />
            <Input
              label="Телефон"
              name="phone"
              placeholder="Введите телефон. Пример: +38 (068) 978-49-90"
              value={phone}
              error={errors.phone}
              onChange={handleChange}
            />
            <TextArea
              label="Сообщение"
              name="message"
              placeholder="Сообщение"
              value={message}
              error={errors.message}
              onChange={handleChange}
            />

            <Button loading={isLoading} onClick={handleSave} disabled={!canSubmit} className="primary">
              отправить
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default React.memo(ContactUs)
