import React from "react"

function Facebook() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 9.86 21.03" width="10" height="20">
      <path className="cls-1"
            d="M6.61,10.57v2.19c0,2.51,0,5,0,7.53,0,.73,0,.74-.76.74H2.77c-.61,0-.63,0-.63-.62,0-3.09,0-6.19,0-9.29,0-.58,0-.57-.59-.56-.39,0-.77,0-1.16,0S0,10.44,0,10.16c0-1,0-1.94,0-2.91,0-.34.14-.43.44-.42s.81,0,1.21,0,.51-.14.51-.52A24.64,24.64,0,0,1,2.23,3.6,3.74,3.74,0,0,1,6,.08C7.1.05,8.21,0,9.32,0c.39,0,.55.12.54.53q0,1.32,0,2.64c0,.42-.16.53-.54.52a13.94,13.94,0,0,0-1.71,0,1,1,0,0,0-1,1c0,.54,0,1.08-.05,1.62,0,.31.1.43.43.42.78,0,1.57,0,2.36,0,.34,0,.46.11.42.47-.1.93-.19,1.86-.25,2.8,0,.43-.23.53-.63.52C8.16,10.55,7.42,10.57,6.61,10.57Z"/>
    </svg>
  )
}

export default Facebook
