import React from "react"
import Facebook from "../Socials/components/facebook"
import Instagram from "../Socials/components/instagram"
import Twitter from "../Socials/components/twitter"
import Telegram from "../Socials/components/telegram"

import './SocialList.scss'

const socials = [
  {
    id: 1,
    title: 'facebook',
    social: <Facebook/>,
    link: process.env.REACT_FACEBOOK,
  },
  {
    id: 2,
    title: 'instagram',
    social: <Instagram/>,
    link: process.env.REACT_INSTAGRAM,
  },
  {
    id: 3,
    title: 'twitter',
    social: <Twitter/>,
    link: process.env.REACT_TWITTER,
  },
  {
    id: 4,
    title: 'telegram',
    social: <Telegram/>,
    link: process.env.REACT_TELEGRAM,
  },
]

function SocialList() {

  return (
    <ul className="SocialList">
      {
        socials.map(item => (
          <li key={item.id} className="SocialList-item">
            <a className="SocialList-link" href={item.link} target="_blank">
              {item.social}
            </a>
          </li>
        ))
      }
    </ul>
  )
}

export default SocialList
