import React from "react"
import logo from "../../../images/BDI-logo.svg"

import "./Footer.scss"

const services = [
  {
    id: 1,
    title: "Наши работы",
    link: "#works",
  },
  {
    id: 2,
    title: "Стоимость",
    link: "#price",
  },
]

function Footer() {

  return (
    <footer id="contacts" className="Footer">
      <div className="container">
        <div className="Footer-content">
          <div className="Footer-logo-w">
            <img src={logo} alt="" className="Footer-logo"/>
            <p>
              Мы предложим комфортные условие сотрудничества, поможем Вам определиться со стилем
              дизайна Вашего будущего интерьера, подберем мебель, аксессуары, планировку и все детали
              Вашего будущего жилья. <br/> Мы поможем сделать правильный выбор!
            </p>
          </div>

          <ul className="Footer-lists">
            <li className="Footer-lists-item">
              <h3 className="Footer-title">
                Сервисы
              </h3>
              <ul className="Footer-list">
                {
                  services.map(item => (
                    <li key={item.id} className="Footer-item">
                      <a href={item.link} className="Footer-link">
                        {item.title}
                      </a>
                    </li>
                  ))
                }
              </ul>
            </li>
            <li className="Footer-lists-item">
              <h3 className="Footer-title">
                Контакты
              </h3>
              <ul className="Footer-list">
                <li className="Footer-item">
                  <a href="tel:0689784990" className="Footer-link">
                    +38 (068) 978-49-90 (Viber/Telegram)
                  </a>
                </li>
                <li className="Footer-item">
                  <a href="tel:0950662174" className="Footer-link">
                    +38 (095) 066-21-74 (Whatsapp)
                  </a>
                </li>
                <li className="Footer-item">
                  <a href="mailto:info@hberry.com.ua" className="Footer-link">
                    info@hberry.com.ua
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
