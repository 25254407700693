import React from "react"

function Telegram() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15 15" width="20" height="20">
      <path className="cls-1"
            d="M15,7.15V7l0-.41,0-.1L14.84,6l-.15-.59L14.5,4.8l-.61-1.22-.14-.22L13.48,3l-.18-.23-.05,0,0-.05-.07-.08h0l-.8-.78h0l0,0,0,0,0,0L12,1.48l-.41-.28-.18-.12-.6-.32L10.44.6,10.18.5,9.58.3,9,.15,8.41.07a6.26,6.26,0,0,0-1.83,0L6,.15,5.42.3l-.6.2L4.56.6,4.23.76l-.6.32-.18.12L3,1.48A8.63,8.63,0,0,0,1.52,3l-.27.39-.14.21L.51,4.77l-.19.57v0h0L.16,6l-.08.5,0,.1L0,7v.78l0,.52L.27,9.53l.2.6L1,11.32l.21.33.18.26a8.33,8.33,0,0,0,2,1.88l.24.15,1.22.59.57.19L6.61,15l.59,0h.6l.59,0,1.19-.24.56-.19,1.23-.59c.34-.26.69-.5,1-.76A6.7,6.7,0,0,0,13.51,12l.06-.07.17-.25.22-.34.57-1.19.2-.6.13-.59.06-.36,0-.31,0-.52Zm-.93.71,0,.48-.11.6-.16.59-.23.6a5,5,0,0,1-.68,1.19l-.28.36-.2.24-.39.39-.22.2-.38.3-1.19.69L9,13.9l-.6.12-.59.05H7.2L6.61,14,6,13.91l-1.2-.41a5.36,5.36,0,0,1-1.18-.69l-.38-.3L3,12.31l-.39-.39-.2-.24-.29-.36-.67-1.19-.23-.6-.16-.59L1,8.34l0-.48V7.15L1,6.55,1.11,6l.14-.47,0-.12.24-.61.31-.58A5.56,5.56,0,0,1,2.74,3L3,2.7a5.54,5.54,0,0,1,1.2-.88l0,0,.54-.28.6-.23L6.61,1,7.2,1h.72A0,0,0,0,1,8,1l.44,0,1.19.28.57.22,0,0h0l.53.27,0,0A5.54,5.54,0,0,1,12,2.7l.29.29a5.18,5.18,0,0,1,.87,1.16l0,0,.31.59.24.6,0,.12.13.47.12.59.06.6v.69S14.07,7.86,14.06,7.86Z"/>
      <path className="cls-1"
            d="M10.18,3.74,9.58,4l-1.19.5-.59.26-.12,0L7.17,5l-.56.24-.21.09-1,.42-1.19.5-.6.25-.2.08L3,6.73c-.1.06-.22.11-.32.18a.63.63,0,0,0-.09,1A1.78,1.78,0,0,0,3,8.17l.48.17.11,0,.6.19c.21,0,.38.12.38.37l.18.59,0,.11.16.49.19.59.24.34a.64.64,0,0,0,.6,0l.48-.39L7,10.29c.12-.12.17,0,.25.08l.46.35.14.11c.19.14.39.28.57.43A.94.94,0,0,0,9,11.5a1.12,1.12,0,0,0,.58-.2h0a1.13,1.13,0,0,0,.28-.58l.1-.53,0,0h0l.19-1,0-.22.11-.59.12-.59.11-.6L10.77,6V6l.12-.59.12-.6v-.6l-.25-.33A.86.86,0,0,0,10.18,3.74Zm0,1.07-.11.56L9.94,6l-.12.6-.12.59-.11.56v0l-.11.59-.12.6-.1.56-.12.63L9,10.59s0,.05-.08.07l-.7-.51-.47-.37-.32-.25L7.2,9.32l-.06,0c-.12-.11-.22-.09-.32,0l-.21.18-.06,0L6,10.09l0,0L6,9.53l.1-.59a.61.61,0,0,1,.09-.2c.12-.13.26-.24.39-.36l0,0,.55-.51,0,0h0l.06-.06h0l.49-.46.13-.12.46-.42.18-.17L9,6.17,9.2,6l.11-.12c.07-.07.11-.17,0-.24a.34.34,0,0,0-.23,0S9,5.59,9,5.61l-.48.29-.71.45-.33.2-.22.13-.64.41-.12.06L6,7.45c-.27.18-.54.36-.82.52s-.25,0-.37-.07l-.44-.15-.16,0-.78-.26,1.38-.56.6-.26.17-.07L6,6.38l.6-.26,1.18-.49.56-.24.07,0L9,5.13l.59-.26.24-.1.4-.18Z"/>
    </svg>
  )
}

export default Telegram
