import { isEmpty } from "lodash"

const isValidEmail = email => (/^[^\s@]{2,}@[^\s@]{2,}\.[^\s@]{2,}$/).test(email)
const isValidPhone = phone => (/^[\+]?[0-9]{2}[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2}[-\s\.]?[0-9]{2}$/).test(phone)

export default ({
                  value,
                  type = "text",
                  required = true,
                  initial = false,
                }) => {
  let error = (isEmpty(value) && "Это поле обязательное!")
    || type === "email" && !isValidEmail(value) && "Почта введена неверно!"
    || type === "phone" && !isValidPhone(value) && "Формат телефона не верный!"
  error = !required && isEmpty(value) ? null : error
  error = error && initial ? null : error
  return error
};
