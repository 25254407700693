import React from "react"
import cn from "classnames"
import { Loader } from "../Loader"

function Button({ children, className, disabled, onClick, loading }) {

  return (
    <button disabled={disabled || loading} onClick={onClick} className={cn("btn", className)}>
      {loading ? <Loader/> : children}
    </button>
  )
}

export default Button
