import React from "react"
import cn from "classnames"
import "./Input.scss"


function Input({
                 value = "",
                 placeholder = "",
                 name = "",
                 label = "",
                 type = "text",
                 error = null,
                 onChange,
               }) {

  return (
    <div className="Input">
      {/*<label>*/}
      {/*  {label}*/}
      {/*</label>*/}
      <input
        className={cn({
          "not-valid": error,
          valid: error === false,
        })}
        value={value}
        placeholder={placeholder}
        name={name}
        type={type}
        onChange={onChange}
      />

      {
        error && (
          <div className="error">
            {error}
          </div>
        )
      }

    </div>
  )
}

export default Input
