import React from "react"

function Twitter() {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 19.37 15.81" width="20" height="20">
      <path className="cls-1"
            d="M13,0h.84l0,0A4,4,0,0,1,16.28,1.2a.23.23,0,0,0,.16,0A7.11,7.11,0,0,0,17.37,1c.5-.19,1-.42,1.46-.64l0,0A4.08,4.08,0,0,1,17.2,2.47h.15A8.79,8.79,0,0,0,19.22,2l.15,0h0A.75.75,0,0,0,19.3,2a7.93,7.93,0,0,1-1.79,1.82.21.21,0,0,0-.09.2A10.08,10.08,0,0,1,17.33,6a11.79,11.79,0,0,1-1.81,4.81,10.83,10.83,0,0,1-5.3,4.3,11.72,11.72,0,0,1-5.69.63A11.19,11.19,0,0,1,.14,14.13L0,14a8.47,8.47,0,0,0,3.09-.28,7.8,7.8,0,0,0,2.75-1.4,4,4,0,0,1-3.7-2.77l.43.05a3.27,3.27,0,0,0,.44,0,3,3,0,0,0,.43,0l.42-.08a4,4,0,0,1-3.12-4A3.75,3.75,0,0,0,2.46,6,4,4,0,0,1,1.31.75,11.41,11.41,0,0,0,9.52,4.91c0-.07,0-.11,0-.15a4.18,4.18,0,0,1-.05-1.17A4,4,0,0,1,12.52.1Z"/>
      <path className="cls-1" d="M18.83.33h0v0Z"/>
      <path className="cls-1" d="M19.37,1.92v0h0v0Z"/>
    </svg>
  )
}

export default Twitter
