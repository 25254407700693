import React from "react"
import cn from "classnames"
import "./TextArea.scss"


function TextArea({
                    value = "",
                    placeholder = "",
                    name = "",
                    label = "",
                    error = null,
                    onChange,
                  }) {

  return (
    <div className="TextArea">
      {/*<label>*/}
      {/*  {label}*/}
      {/*</label>*/}
      <textarea
        className={cn({
          "not-valid": error,
          valid: error === false,
        })}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
      />

      {
        error && (
          <div className="error">
            {error}
          </div>
        )
      }

    </div>
  )
}

export default TextArea
